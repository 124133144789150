import React, { useState, useCallback, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ProductCard from '../../components/ProductCard/ProductCard';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useSelector, useDispatch} from 'react-redux';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';
import { getProducts } from "../../redux/products/productsActions";
import NavigationBar from '../../components/NavigationBar';
import PageSpinner from '../../components/PageSpinner';

const theme = createTheme();

const EmptyComponent = () => (
  <MDBCard style={{textAlign: 'center'}}>
  <MDBCardBody>
    <MDBCardTitle>Momentan nu avem produse în Shop!</MDBCardTitle>
    <MDBCardText>
      Vom actualiza produsele cât de curând!
    </MDBCardText>
  </MDBCardBody>
</MDBCard>
)


export default function ShopPage() {
  const dispatch = useDispatch();
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { products, loading, error, success } = useSelector(
    (state) => state.products
  );

  const { userInfo, success: userSuccess } = useSelector((state) => state.user);
  const getShopData = useCallback(() => dispatch(getProducts()), [dispatch]);

  useEffect(() => {
    getShopData();
  }, [getShopData]);

  useEffect(() => {
    if (products) {
      const sameProducts = products.filter((f) =>
        userInfo?.products?.some((item) => item.id === f.id)
      );
      const filtered = products.filter((item) => !sameProducts.includes(item));
      setFilteredProducts(filtered);
    }
  }, [products, userSuccess, userInfo?.products]);

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar />
      <Container component="main" maxWidth="lg">
      <h3>Shop</h3>
      <Divider style={{marginBottom: 20}}/>
      {products.length ? (
        <Grid container spacing={2}>
          {filteredProducts.map((item, index) => <Grid item xs={12} sm={6} md={4} key={item.id}>
              <ProductCard product={{...item.attributes, id: item.id}} />
            </Grid>
          )}
        </Grid>
      ): null}
      {!filteredProducts.length ? <EmptyComponent /> : null}
      </Container>
      <div style={{ marginTop: 50 }}></div>
      <PageSpinner isLoading={loading} />
    </ThemeProvider>
  )
}
