
import axios from 'axios';
import env from '../../env';

const instance = axios.create({
  baseURL: env.API_BASE,
  timeout: 10000
});

// Request interceptor
instance.interceptors.request.use(
  async (config) => {
    const userToken = localStorage.getItem("userToken");
    if (userToken && !config.url.includes('/auth/local')) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Error interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error instanceof TypeError) {
      return;
    }

    if (error?.response?.status === 401) {
      // Redirect the user to login screen
      localStorage.removeItem("userToken");
      window.location.replace('/');
    }
    return Promise.reject(error?.response?.data?.error);
  }
);

export default instance;
