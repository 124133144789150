import React, { useEffect } from "react";
import logo from "../../assets/laura_logo_white.png";
import { useNavigate } from "react-router-dom";

const DownloadAppPage = ({navigation}) => {
	const navigate = useNavigate();
	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		if (/android/.test(userAgent)) {
			window.location.href = "https://play.google.com/store/apps/details?id=com.lauramutu.nailsandgo";
		} else if (/iphone|ipad|ipod/.test(userAgent)) {
			window.location.href = "https://apps.apple.com/ro/app/nails-go-by-laura-mutu-academy/id6444667816";
		} else {
			navigate("/");
		}
	}, []);
	
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<img src={logo} alt="Logo" style={{ filter: "invert(100%)" }} />
		</div>
	);
};

export default DownloadAppPage;
