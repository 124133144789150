import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { MDBIcon } from 'mdb-react-ui-kit';

const theme = createTheme();

export default function PaymentErrorPage() {
    const navigate = useNavigate();
    const params = useLocation();
    const { error } = params.state || {};
    const goToYourProducts = () => navigate("/produsele-mele");

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl" style={{ flex: 1, display: 'flex', height: '100vh' }}>
          <CssBaseline />
          <Grid container spacing={1} style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={6}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MDBIcon fas icon="exclamation-triangle" style={{fontSize: 70, marginBottom: 20, color: 'red'}}/>
                <p style={{fontWeight: 'bold'}}>A aparut o problemă la procesarea plății!</p>
                <p style={{marginBottom: 50}}>{error ? error : "Te rugăm să încerci mai târziu."}</p>
                <Button variant="contained" onClick={goToYourProducts}>Înapoi la produsele tale</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
}
