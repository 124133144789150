import React, { useState, useEffect } from 'react';
import './App.css';
import { store } from "./store";
import { Provider } from "react-redux";
import OrganizerPage from './pages/OrganizerPage/OrganizerPage';
import initRequestInterceptor from "./services/interceptors/RequestInterceptor";
import initErrorInterceptor from "./services/interceptors/ErrorsInterceptor";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import ENV from './env';

function App() {
  initRequestInterceptor();
  initErrorInterceptor();

  const [publishableKey, setPublishableKey] = useState("");

  const getPublishableKey = async () => {
    const response = await axios.get(
      `${ENV.API_BASE}/api/get-payment-publishableKey`
    );

    if (response.data) {
      const apiKey = loadStripe(response.data.publishableKey)
      setPublishableKey(apiKey);
    }
  };

  useEffect(() => {
    getPublishableKey()
  }, []);

  if(!publishableKey) {
    return
  }

  return (
    <Provider store={store}>
      <Elements stripe={publishableKey}>
        <OrganizerPage />
      </Elements>
    </Provider>
  );
}

export default App;
