import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import ProtectedRoutes from '../../ProtectedRoutes';
import LoginPage from '../LoginPage/LoginPage';
import PaymentErrorPage from '../PaymentPage/PaymentErrorPage';
import PaymentPage from '../PaymentPage/PaymentPage';
import PublicPaymentPage from '../PublicPaymentPage/PublicPaymentPage';
import PaymentSuccessPage from '../PaymentPage/PaymentSuccessPage';
import ProductPage from '../ProductPage/ProductPage';
import PublicProductPage from '../PublicProductPage/PublicProductPage';
import ProductsListPage from '../ProductsListPage/ProductsListPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import ShopPage from '../ShopPage/ShopPage';
import LandingPage from '../LandingPage/LandingPage';
import DownloadAppPage from '../DownloadAppPage/DownloadAppPage';
import AdminPage from '../AdminPage/AdminPage';


export default function OrganizerPage() {
  const user = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const isAuthenticated = localStorage.getItem('userToken');

  useEffect(() => {
    setIsLoggedIn(user.isAuthenticated);
  }, [user]);

  return (
    <div>
      <CssBaseline />
      <Router>
        <Routes>
          <Route exact path="/" element={isAuthenticated ? <Navigate to="/produsele-mele" /> : <LandingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          <Route exact path="/webinars/:id" element={<PublicProductPage />} />
          <Route exact path="/public-payment" element={<PublicPaymentPage />} />
          <Route exact path="/payment-success" element={<PaymentSuccessPage />} />
          <Route exact path="/payment-error" element={<PaymentErrorPage />} />
          <Route exact path="/descarca-aplicatie" element={<DownloadAppPage />} />
          <Route element={<ProtectedRoutes auth={isLoggedIn} />}>
            <Route exact path="/produsele-mele" element={<ProductsListPage />} />
            <Route exact path="/shop" element={<ShopPage />} />
            <Route exact path="/product/:id" element={<ProductPage />} />
            <Route exact path="/payment" element={<PaymentPage />} />
            <Route exact path="/admin" element={<AdminPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  )
}
