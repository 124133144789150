import React, { useEffect, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBFooter,
  MDBSpinner 
} from "mdb-react-ui-kit";
import BackgroundImage from '../../assets/background.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from "../../redux/products/productsActions";
import NavigationBar from '../../components/NavigationBar';

const theme = createTheme();

export default function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { products, loading, error } = useSelector((state) => state.products);

  const fetchProducts = useCallback(() => dispatch(getProducts()), [dispatch]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar isGuest />

      <div
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          height: "500px",
          marginTop: -50,
          backgroundPosition: "right",
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="mb-3">Laura Mutu Academy</h1>
              <h4 className="mb-3">Experimentează arta frumuseții într-un loc unde stilul se întâlnește cu rafinamentul.</h4>
            </div>
          </div>
        </div>
      </div>

      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol>
            <h2 className="text-center mb-5 shadow-2 p-4">Webinarii</h2>
          </MDBCol>
        </MDBRow>
        {error 
          ? <MDBCard className="shadow">
              <MDBCardBody>
                <MDBCardText className='text-center text-danger'>
                  A apărut o eroare! Te rugăm să încerci mai târziu.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          : <div>
              {
                loading 
                  ? <div className='text-center d-flex justify-content-center'> 
                      <MDBSpinner grow>
                        <span className='visually-hidden'>Loading...</span>
                      </MDBSpinner> 
                      <h3 style={{marginLeft: '15px'}}>Loading...</h3>
                    </div>
                  : <MDBRow>
                      <MDBCol>
                        <MDBRow className="row-cols-1 row-cols-md-3 g-4">
                          {products.map((product) => (
                            <MDBCol key={product.id} onClick={() => navigate(`/webinars/${product.id}`)}>
                            <MDBCard>
                              <MDBCardImage
                                src="https://nailsandgoproducts.b-cdn.net/webinars_poster.png"
                                alt="..."
                                position="top"
                              />
                              <MDBCardBody>
                                <MDBCardTitle>{product?.attributes?.name}</MDBCardTitle>
                                <MDBCardText>
                                  {product?.attributes?.shortDescription}
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                          ))}

                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
              }
            </div>}
      </MDBContainer>

      <MDBFooter bgColor='dark' className='text-center text-lg-left mt-5'>
      <div className='text-center p-3' style={{color: 'white', backgroundColor: 'rgba(0, 0, 0, 1)' }}>
        &copy; {new Date().getFullYear()} Copyright{' '}
        <a className='text-light' href='https://lauramutuacademy.ro'>
          Laura Mutu Academy
        </a>
      </div>
    </MDBFooter>
    </ThemeProvider>
  );
}
