import axios from "axios";

const requestInterceptor = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    async (config) => {
      const userToken = localStorage.getItem("userToken");
      if (userToken && !config.url.includes("/auth/local")) {
        config.headers.Authorization = "Bearer " + userToken;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

export default requestInterceptor;
