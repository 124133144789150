import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardBG from '../../assets/placeholder.png';
import { useNavigate } from 'react-router-dom';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard({product}) {
  const navigate = useNavigate();

  return (
    <Card
      style={{backgroundImage: `url(${CardBG})`, backgroundSize: 'cover', backgroundPosition: 'center', cursor: 'pointer'}}
      onClick={() => navigate(`/product/${product.id}`)}>
      <CardContent style={{backgroundColor: 'rgba(0,0,0, 0.8)', height: '100%', minHeight: '150px'}}>
        <Typography sx={{ fontSize: 20, color: 'white' }} color="text.secondary" gutterBottom>
          {product.name}
        </Typography>

        <Typography sx={{ fontSize: 14, color: 'white' }} component="div">
          {product.shortDescription}
        </Typography>
      </CardContent>
    </Card>
  );
}
