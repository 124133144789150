import axios from "axios";

const errorsInterceptor = () => {
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err?.response?.data?.error?.status === 401) {
        localStorage.removeItem("userToken");
        window.location.replace('/');
      }

      return err;
    }
  );
};

export default errorsInterceptor;
