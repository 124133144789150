import { createSlice } from "@reduxjs/toolkit";
import { getProducts, updateProductState, assignProductAttempt } from "./productsActions";

const initialState = {
  loading: false,
  products: [],
  error: null,
  success: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    resetProductsState: (state) => {
      state.loading = false;
      state.products = [];
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: {
    // get products
    [getProducts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProducts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload.data;
      state.error = null;
      state.success = true;
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },

    // update product state
    [updateProductState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProductState.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload.data;
      state.success = true;
    },
    [updateProductState.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },

    // assign product
    [assignProductAttempt.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [assignProductAttempt.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload.data;
      state.success = true;
    },
    [assignProductAttempt.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
  },
});


export const { resetProductsState } = productsSlice.actions;
export default productsSlice.reducer;
