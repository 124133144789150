import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import ENV from "../../env";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ identifier, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        // headers: {
        //   "Content-Type": "application/json",
        // },
      };

      let request = await axios.post(`${ENV.API_BASE}/api/auth/local`, { identifier, password }, config);

      if (!request.data) {
        const errorMessage = request?.response?.data?.error?.name === "ValidationError"
          ? "Datele de logare sunt incorecte!"
          : request?.response?.data?.error?.message;

        throw new Error(errorMessage);
      }
      // store user's token in local storage
      localStorage.setItem("userToken", request.data.jwt);

      const userData = await axios.get(
        `${ENV.API_BASE}/api/users/me?populate=role&populate=products&populate=favoriteProducts`,
        config
      );

      request.data.user = userData.data;

      return request.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userRegister = createAsyncThunk(
  "user/register",
  async (payload, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${ENV.API_BASE}/api/auth/local/register`,
        payload,
        config
      );

      return data;
    } catch (error) {
      const convertMessage = (message) => {
        switch (message) {
          case "username must be at least 3 characters":
            return "Numele de utilizator trebuie sa aibă cel puțin 3 caractere.";

          case "password must be at least 6 characters":
            return "Parola trebuie să conțina cel puțin 6 caractere.";

          case "Email or Username are already taken":
            return "Emailul sau numele de utilizator sunt deja înregistrate.";

          default:
            return message;
        }
      };
      // return custom error message from API if any
      if (error.response.data.error.details?.errors?.length) {
        let multipleMessages = "";
        error.response.data.error.details?.errors.forEach((error) => {
          multipleMessages += `${convertMessage(error.message)}\n`;
        });
        return rejectWithValue(multipleMessages);
      }

      if (error.response && error.response.data.error.message) {
        let message = convertMessage(error.response.data.error.message);
        return rejectWithValue(message);
      }

      return rejectWithValue(error.message);
    }
  }
);

export const userInfo = createAsyncThunk(
  "user/getInfo",
  async (undefined, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${ENV.API_BASE}/api/users/me?populate=role&populate=products&populate=favoriteProducts`,
        config
      );
      const userToken = localStorage.getItem("userToken");
      return {
        userInfo: data,
        userToken,
      };
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (undefined, { rejectWithValue }) => {
    try {
      localStorage.removeItem("userToken");
      return;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "user/getAllUsers",
  async (undefined, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${ENV.API_BASE}/api/users?populate=%2A&filters[isAdmin][$in]=false`,
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userUpdate = createAsyncThunk(
  "user/update",
  async ({ payload, userId }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.put(
        `${ENV.API_BASE}/api/users/${userId}`,
        payload,
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ userId }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.delete(
        `${ENV.API_BASE}/api/users/${userId}`,
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async ({ password, passwordConfirmation, code }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${ENV.API_BASE}/api/auth/reset-password`,
        { password, passwordConfirmation, code },
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
