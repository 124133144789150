import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { MDBInput } from "mdb-react-ui-kit";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import NavigationBar from "../../components/NavigationBar";
import PageSpinner from "../../components/PageSpinner";
import ENV from "../../env";
import RegisterPage from "../RegisterPage/RegisterPage";
import { toast } from "react-toastify";
import { assignProductAttempt } from "../../redux/products/productsActions";
import { resetProductsState } from "../../redux/products/productsSlice";

const theme = createTheme();

export default function PublicPaymentPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const elements = useElements();
  const stripe = useStripe();
  const product = params?.state?.product;
  const assignProductState = useSelector((state) => state.products);

  const [cardName, setCardName] = useState("");
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [triggerAuthentication, setTriggerAuthentication] = useState(false);
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!product) {
      navigate("/");
    }
  }, [product, navigate]);

  const getErrorMessageByType = (error) => {
    if (error?.code === "card_declined") {
      switch (error.decline_code) {
        case "generic_decline":
          return "Tranzacția a fost refuzată. Vă rugăm să verificați detaliile cardului și să încercați din nou.";
        case "insufficient_funds":
          return "Fonduri insuficiente!";
        case "lost_card":
          return "Cardul este raportat pierdut! Te rugăm să iei legătura cu banca.";
        case "stolen_card":
          return "Cardul este raportat furat de către bancă! Te rugăm să iei legătura cu banca.";
        default:
          return "Tranzacția a fost refuzată. Vă rugăm să verificați detaliile cardului și să încercați din nou.";
      }
    } else if (error?.code === "expired_card") {
      return "Card expirat!";
    } else if (error?.code === "incorrect_cvc") {
      return "Cod de siguranță (CVC) incorect!";
    }
    return "A apărut o eroare! Te rugăm să încerci din nou.";
  };

  const assignProduct = (userData) => {
    dispatch(assignProductAttempt({ productId: product?.id, userId: userData?.user?.id }));
  };

  const handleError = (error) => {
    setPaymentLoading(false);
    const errorMessage = getErrorMessageByType(error);
    toast(errorMessage || "A apărut o eroare! Te rugăm să încerci din nou.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTriggerAuthentication(true);
    setPaymentLoading(true);
  };

  const handlePayment = async (userData) => {
    if(userData.hasOwnProperty("jwt")) {
      setUser(userData);
    } else {
      userData = user;
    }

    setTriggerAuthentication(false);
    setPaymentStarted(true);
    if (!stripe || !elements || !cardName || !cardDetailsComplete) {
      return;
    }
  
    setPaymentLoading(true);
  
    const postData = {
      paymentMethodType: ["card"],
      amount: product.price,
      email: userData.user?.email,
      name: `${userData?.user?.firstName} ${userData?.user?.lastName}`,
      currency: "ron",
      description: `Nume: ${userData?.user?.firstName} ${userData?.user?.lastName} Produs: ${product.name}`,
      stripeCustomerId: userData?.user?.stripeCustomerId || "cus_Mo9wmbkxWOrHwM",
    };
  
    try {
      const response = await axios.post(`${ENV.API_BASE}/api/create-payment-intent`, postData);
  
      if (response?.status === 200 && response?.data?.clientSecret) {
        const { clientSecret } = response.data;
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardName,
              email: userData?.user?.email,
              phone: userData?.user?.phoneNumber,
            },
          },
          receipt_email: userData.user?.email,
        });
  
        if (paymentIntent) {
          // Go to success
          setPaymentLoading(false);
          setPaymentStarted(false);
          assignProduct(userData);
        } else {
          // Go to error
          handleError(error);
        }
      } else {
        // Go to error
        handleError(null);
      }
    } catch (error) {
      // handle errors
      handleError(error);
    }
  };

  if (!product) return null;
  
  if(assignProductState?.success) {
    dispatch(resetProductsState());
    navigate("/payment-success");
  }

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar isGuest />
      <PageSpinner isLoading={paymentLoading} />
      <Container
        component="main"
        maxWidth="xl"
        style={{
          marginTop: "50px",
          justifyContent: "center",
          paddingBottom: 50,
        }}
      >
        <CssBaseline />
        <RegisterPage
          isIntegrated
          triggerAuthentication={triggerAuthentication}
          handleRegisterSuccess={handlePayment}
          handleRegisterFail={() => {
            setTriggerAuthentication(false);
            setPaymentLoading(false);
          }}
        />
        <Grid container spacing={1} style={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={12} md={6}>
            <h5>{product.name}</h5>
            <p>{product.shortDescription}</p>
            <Divider style={{ marginBottom: 20 }} />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p style={{ marginRight: 20 }}>Total</p>
              <p>
                <strong>{product.price} RON</strong>
              </p>
            </div>
            <Divider style={{ marginBottom: 20 }} />
            <form onSubmit={handleSubmit}>
              <MDBInput
                label="Nume beneficiar card"
                id="cardName"
                type="text"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
                style={{ marginBottom: 20 }}
              />
              <div
                style={{
                  border: "1px solid #bdbdbd",
                  padding: 10,
                  borderRadius: "0.25rem",
                }}
              >
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: { base: { marginBottom: 20, fontSize: "1rem" } },
                  }}
                  onChange={(e) => setCardDetailsComplete(e.complete)}
                />
              </div>
              <Button
                disabled={!(cardDetailsComplete && cardName)}
                fullWidth
                variant="contained"
                onClick={paymentStarted ? handlePayment : handleSubmit}
                style={{ marginTop: 20 }}
              >
                Plătește
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
