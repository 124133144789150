import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userInfo as getUserInfo } from './redux/user/userActions';

const ProtectedRoutes = () => {
	const isAuthenticated = localStorage.getItem('userToken');
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(getUserInfo());
	}, [dispatch, user?.userUpdate_success]);

	if(isAuthenticated) {
		return <Outlet />
	}

	return <Navigate to="/" replace />
}
export default ProtectedRoutes;
