import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getAllUsers,
  resetPassword,
  userInfo,
  userLogin,
  userLogout,
  userRegister,
  userUpdate
} from "./userActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  userInfo: null,
  userToken: null,
  users: [],
  isAuthenticated: false,

  register_loading: false,
  register_error: null,
  register_success: false,

  userUpdate_loading: false,
  userUpdate_error: null,
  userUpdate_success: false,

  deleteUser_loading: false,
  deleteUser_error: null,
  deleteUser_success: false,

  resetPassword_loading: false,
  resetPassword_error: null,
  resetPassword_success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.userToken = payload.jwt;
      state.error = null;
      state.success = true;
      state.isAuthenticated = true && state.userToken;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
      state.isAuthenticated = false;
    },

    // register user
    [userRegister.pending]: (state) => {
      state.register_loading = true;
      state.register_error = null;
    },
    [userRegister.fulfilled]: (state) => {
      state.register_loading = false;
      state.userInfo = null;
      state.register_error = null;
      state.register_success = true;
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.register_loading = false;
      state.register_error = payload;
      state.register_success = false;
    },

    // user info
    [userInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [userInfo.fulfilled]: (state, { payload }) => {
      const { userInfo, userToken } = payload;
      state.loading = false;
      state.userInfo = userInfo;
      state.userToken = userToken;
      state.error = null;
      state.success = true;
      state.isAuthenticated = true && state.userToken;
    },
    [userInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },

    // user logout
    [userLogout.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogout.fulfilled]: (state) => {
      state = initialState;
    },
    [userLogout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // get all users
    [getAllUsers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.users = payload;
      state.success = true;
    },
    [getAllUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // user update
    [userUpdate.pending]: (state) => {
      state.userUpdate_loading = true;
      state.userUpdate_error = null;
      state.userUpdate_success = false;
    },
    [userUpdate.fulfilled]: (state) => {
      state.userUpdate_loading = false;
      state.userUpdate_error = null;
      state.userUpdate_success = true;
    },
    [userUpdate.rejected]: (state, { payload }) => {
      state.userUpdate_loading = false;
      state.userUpdate_error = payload;
      state.userUpdate_success = false;
    },

    // delete user
    [deleteUser.pending]: (state) => {
      state.deleteUser_loading = true;
      state.deleteUser_error = null;
      state.deleteUser_success = false;
    },
    [deleteUser.fulfilled]: (state) => {
      state.deleteUser_loading = false;
      state.deleteUser_error = null;
      state.deleteUser_success = true;
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.deleteUser_loading = false;
      state.deleteUser_error = payload;
      state.deleteUser_success = false;
    },

    // reset password
    [resetPassword.pending]: (state) => {
      state.resetPassword_loading = true;
      state.resetPassword_error = null;
      state.resetPassword_success = false;
    },
    [resetPassword.fulfilled]: (state) => {
      state.resetPassword_loading = false;
      state.resetPassword_error = null;
      state.resetPassword_success = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.resetPassword_loading = false;
      state.resetPassword_error = payload;
      state.resetPassword_success = false;
    },
  },
});

export default userSlice.reducer;
