import ENV from "../env.js";
import axiosInstance from "../services/interceptors/AxiosInterceptor.js";

const ProductService = () => ({
  assignProduct: async ({ productId, userId }) => {
    try {
      const response = await axiosInstance.post(
        `${ENV.API_BASE}/api/assignProductToUser`,
        {
          productId,
          userId,
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
});

export default ProductService();
