import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../../services/ProductService";

import ENV from "../../env";

export const getProducts = createAsyncThunk(
  "products/get_products",
  async (user, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const url = `${ENV.API_BASE}/api/products?populate=%2A`;

      const { data } = await axios.get(url, config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProductState = createAsyncThunk(
  "products/update_product",
  async ({ state, productId, user }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.put(
        `${ENV.API_BASE}/api/appointments/${productId}`,
        { data: { personHasArrived: state } },
        config
      );

      const url = user.isAdmin
        ? `${ENV.API_BASE}/api/appointments?populate=%2A`
        : `${ENV.API_BASE}/api/appointments?populate=%2A&filters\[users_permissions_user\][id][$in]=${user.id}`;

      const { data } = await axios.get(url, config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assignProductAttempt = createAsyncThunk(
  "products/assign_product",
  async ({ productId, userId }, { rejectWithValue }) => {
    try {
      const product = await ProductService.assignProduct({ productId, userId }); 
      
      return product;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(error);
    }
  }
);


