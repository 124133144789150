import React, { useEffect, useState, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ProductCard from '../../components/ProductCard/ProductCard';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import {
  MDBCard,
  MDBCardBody,
  MDBCardText
} from 'mdb-react-ui-kit';
import NavigationBar from '../../components/NavigationBar';
import PageSpinner from '../../components/PageSpinner';
import { useDispatch } from 'react-redux';
import { getProducts } from "../../redux/products/productsActions";

const theme = createTheme();

const EmptyComponent = () => (
  <MDBCard style={{textAlign: 'center'}}>
  <MDBCardBody>
    <MDBCardText>Nu aveți niciun produs disponibil!</MDBCardText>
  </MDBCardBody>
</MDBCard>
)


export default function ProductsListPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLoading = user?.loading;
  const { products: shopProducts, loading, error } = useSelector((state) => state.products);

  const fetchProducts = useCallback(() => dispatch(getProducts()), [dispatch]);

  const [products, setProducts] = useState([]);


  useEffect(() => {
    const products = user?.userInfo?.products || [];
    fetchProducts();
    if(shopProducts.length && products.length) {
      const filteredAndMappedProducts = shopProducts.reduce((result, item) => {
        if (item.attributes.price === 0) {
          result.push({id: item.id, ...item.attributes});
        }
        return result;
      }, []);
      setProducts([...products, ...filteredAndMappedProducts]);
    }
  }, [user?.userUpdate_success, user?.userInfo?.products, shopProducts])

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar />
      <Container component="main" maxWidth="lg">
      <h3>Produsele mele</h3>
      <Divider style={{marginBottom: 20}}/>
      {products.length ? (
        <Grid container spacing={2}>
          {products.map((item, index) => <Grid item xs={12} sm={6} md={4} key={item.id}><ProductCard product={item} /></Grid>)}
        </Grid>
      ): null}
      {!products.length ? <EmptyComponent /> : null}
      </Container>
      <PageSpinner isLoading={isLoading} />
      <div style={{ marginTop: 50 }}></div>
    </ThemeProvider>
  )
}
