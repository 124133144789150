import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from '../../redux/user/userActions';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © Laura Mutu Academy '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const theme = createTheme();

export default function ResetPasswordPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetPassword_success, resetPassword_error, resetPassword_loading } = useSelector((state) => state.user);
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  useEffect(() => {
    if (resetPassword_success) {
      toast("Parola a fost modificată cu succes!", {
        type: "success"
      })
      setTimeout(() => navigate("/login"), 2000);
    } else if (resetPassword_error) {
      toast("A aparut o problemă. Te rugăm să încerci din nou!", {
        type: "error"
      })
    }
  }, [resetPassword_success, resetPassword_error, navigate])

  const handleResetPassword = () => {
    if (!code) {
      toast("A aparut o problemă. Te rugăm să încerci din nou!", {
        type: "error"
      })
      return
    }
    if (newPassword !== passwordConfirmation) {
      toast("Parola nu coincide.", {
        type: "info"
      })
      return
    }

    dispatch(resetPassword({ password: newPassword, passwordConfirmation, code }));
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Resetare parolă
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="Parolă nouă"
                name="newPassword"
                type="password"
                autoComplete="newPassword"
                autoFocus
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="passwordConfirmation"
                label="Confirmă Parolă"
                name="passwordConfirmation"
                type="password"
                autoComplete="passwordConfirmation"
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
              <Button
                disabled={resetPassword_loading}
                onClick={handleResetPassword}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Schimbă parola
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}
