import React, { useState, useCallback, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import NavigationBar from '../../components/NavigationBar';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../redux/products/productsActions';
import { getAllUsers } from '../../redux/user/userActions';
import { assignProductAttempt } from '../../redux/products/productsActions';
import { ToastContainer, toast } from 'react-toastify';
import { resetProductsState } from '../../redux/products/productsSlice';

const showToastSuccess = (error) => toast("Produsul a fost asginat!", {
  type: "success"
});

const showToastError = (error) => toast("A aparut o eroare, te rugam sa incerci mai tarziu!", {
  type: "error"
});

const AdminPage = () => {
  const dispatch = useDispatch();
  const [assignTriggered, setAssignTriggered] = useState(false);
  const [product, setProduct] = useState('');
  const [user, setUser] = useState('');

  const [mappedUsers, setMappedUsers] = useState([]);
  const [mappedProducts, setMappedProducts] = useState([]);

  const users = useSelector((state) => state.user.users);
  const {products, success, error} = useSelector((state) => state.products);
  console.log("{products, success, error}: ", {products, success, error})
  const getShopData = useCallback(() => dispatch(getProducts()), [dispatch]);
  const getUsers = useCallback(() => dispatch(getAllUsers()), [dispatch]);

  useEffect(() => {
    getShopData();
    getUsers();
  }, []);

  useEffect(() => {
    setMappedUsers(users.map((user) => {
      return {name: user.email, value: user.id};
    }));
  }, [users]);

  useEffect(() => {
    if(products?.length) {
      setMappedProducts(products
        .filter((product) => product.attributes.price !== 0)
        .map((product) => {
          return { name: product.attributes.name, value: product.id };
        }));
      }
    }, [products]);


    if(success && assignTriggered) {
      showToastSuccess();
      setAssignTriggered(false);
      dispatch(resetProductsState());
    }

    if(error && assignTriggered) {
      showToastError();
      setAssignTriggered(false);
    }


  const assignProduct = () => {
    if (!product || !user) {
      return;
    }

    dispatch(assignProductAttempt({ productId: product, userId: user }));
  };

  return (
    <>
      <NavigationBar />
      <MDBContainer className='shadow'>
        <MDBRow>
          <MDBCol>
            <h2>Admin Page</h2>
            <section style={{marginTop: 50}}>
              <h3>Asigneaza produse</h3>
              <MDBRow className='mb-3'>
                  <SelectSearch 
                    search={true}
                    options={mappedUsers} 
                    value={user} 
                    name="language" 
                    placeholder="Cauta dupa email" 
                    onChange={setUser}
                  />
              </MDBRow>
              <MDBRow className='mb-3'>
                <SelectSearch
                  search={true}
                  options={mappedProducts} 
                  value={product} 
                  name="language" 
                  placeholder="Cauta produs" 
                  onChange={setProduct}
                />
              </MDBRow>
              <MDBRow>
                <MDBCol md="5" className='mt-3 mb-3'>
                  <MDBBtn color="primary" style={{width: '100%'}} onClick={() => {
                    setAssignTriggered(true);
                    assignProduct();
                  }}>Asigneaza</MDBBtn>
                </MDBCol>
              </MDBRow>
            </section>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default AdminPage;
