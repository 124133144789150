import axios from "axios";
import ENV from "../env.js";
import axiosInstance from '../services/interceptors/AxiosInterceptor.js';

const apiConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
};

const AuthService = () => ({
  login: async (credentials) => {
    const { identifier, password } = credentials;

    const { data } = await axios.post(`${ENV.API_BASE}/api/auth/local`, {
      identifier,
      password,
    });

    return data;
  },
  register: async (payload) => {
    try {
      const data = await axiosInstance.post(
        `${ENV.API_BASE}/api/auth/local/register`,
        payload,
        apiConfig
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
});

export default AuthService();
