import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Laura Mutu Academy "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp({ isIntegrated, triggerAuthentication, handleRegisterSuccess, handleRegisterFail }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(true);

  const validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      setEmail(text);
      setEmailValidation(false);
    } else {
      setEmail(text);
      setEmailValidation(true);
    }
  };

  const submitForm = async () => {
    if (email && !emailValidation) {
      showToastInfo("Email invalid!");
    }

    if (password !== confirmPassword) {
      showToastInfo("Parola nu coincide!");
    }

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !password ||
      !confirmPassword
    ) {
      showToastInfo();
    }

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !emailValidation ||
      !password ||
      password !== confirmPassword
    ) {
      return;
    }

    const payload = {
      username,
      email,
      password,
      firstName,
      lastName,
      phoneNumber: phone,
      roleId: 4, // Customer id
    };

    AuthService.register(payload)
      .then((result) => {
        if(!isIntegrated) {  
        showToastSuccess();
        setTimeout(() => {
          navigate("/login");
        }, 2000); 
      } else {
        handleRegisterSuccess(result?.data);
      }
      })
      .catch((err) => {
          if (
            err?.response?.data?.error?.message ===
            "password must be at least 6 characters"
          ) {
            showToastError("Parola trebuie să conțină minim 6 caractere.");
            return;
          }
          showToastError(err?.message);
          handleRegisterFail();
      });
  };

  const showToastError = (message) =>
    toast(message || "A apărut o problemă. Te rugăm să încerci mai târziu!", {
      type: "error",
    });

  const showToastInfo = (message) =>
    toast(message || "Toate câmpurile sunt obligatorii!", {
      type: "info",
    });

  const showToastSuccess = () =>
    toast("Te-ai înregistrat cu succes!", {
      type: "info",
    });

  useEffect(() => {
    if (triggerAuthentication) {
      submitForm();
    }
  }, [triggerAuthentication]);

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={isIntegrated ? "sm" : "xs"}
        style={{ marginBottom: isIntegrated ? 50 : 0 }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Înregistrare
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="user-name"
                  name="userName"
                  required
                  fullWidth
                  id="userName"
                  label="Nume utilizator"
                  autoFocus
                  onChange={(event) => setUsername(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Prenume"
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Nume"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Telefon"
                  name="phone"
                  autoComplete="phone"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => validateEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Parolă"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirmă parola"
                  type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                </Grid> */}
            </Grid>
            {!isIntegrated && (
              <>
                <Button
                  fullWidth
                  onClick={submitForm}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Înregistrare
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Ai deja un cont? Autentifică-te
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
        {!isIntegrated && <Copyright sx={{ mt: 5 }} />}
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}
