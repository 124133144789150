import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { MDBInput } from 'mdb-react-ui-kit';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import NavigationBar from '../../components/NavigationBar';
import PageSpinner from '../../components/PageSpinner';
import { userUpdate } from "../../redux/user/userActions";
import ENV from '../../env';

const theme = createTheme();

export default function PaymentPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useLocation();
    const elements = useElements();
    const stripe = useStripe();
    const product = params?.state?.product;
    const { userInfo, userUpdate_success } = useSelector((state) => state.user);

    const [cardName, setCardName] = useState("");
    const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);

    useEffect(() => {
      if(!product) {
        navigate("/");
      }
    }, [product, navigate]);

    useEffect(() => {
      if(userUpdate_success) {
        setPaymentLoading(false);
        navigate("/payment-success");
      }
    }, [userUpdate_success, navigate])

    const getErrorMessageByType = (error) => {
        if (error.code === "card_declined") {
          switch (error.decline_code) {
            case "generic_decline":
              return null;
            case "insufficient_funds":
              return "Fonduri insuficiente!";
            case "lost_card":
              return "Cardul este raportat pierdut! Te rugăm să iei legătura cu banca.";
            case "stolen_card":
              return "Cardul este raportat furat de către bancă! Te rugăm să iei legătura cu banca.";
            default:
              return null;
          }
        } else if (error.code === "expired_card") {
          return "Card expirat!";
        } else if (error.code === "incorrect_cvc") {
          return "Cod de siguranță (CVC) incorect!";
        }
        return null;
    };

    const assignProduct = () => {
        const productsIDs = userInfo.products.map((product) => product.id);
        productsIDs.push(product.id);
        dispatch(
          userUpdate({ payload: { products: productsIDs }, userId: userInfo.id })
        );
        setPaymentLoading(true);
    };

    const handleError = (error) => {
      setPaymentLoading(false);
      const errorMessage = getErrorMessageByType(error)
      navigate("/payment-error", {state: { error: errorMessage }})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!stripe || !elements || !cardName || !cardDetailsComplete) {
            return;
        }

        setPaymentLoading(true);

        try {
            const response = await axios.post(`${ENV.API_BASE}/api/create-payment-intent`, {
                    paymentMethodType: ["card"],
                    amount: product.price,
                    email: userInfo.email,
                    name: `${userInfo.firstName} ${userInfo.lastName}`,
                    currency: "ron",
                    description: `
                    Nume: ${userInfo.firstName} ${userInfo.lastName}
                    Produs: ${product.name}
                    `,
                    stripeCustomerId: "cus_Mo9wmbkxWOrHwM" || userInfo.stripeCustomerId,
                  }
            );

            if (response.status === 200 && response?.data?.clientSecret) {
                const { clientSecret } = response.data;
              try {
                const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: cardName,
                            email: userInfo.email,
                            phone: userInfo.phone.toString(),
                        },
                    },
                    receipt_email: userInfo.email
                });

                if (paymentIntent) {
                  // Go to success
                  setPaymentLoading(false);
                  assignProduct();
                } else {
                  // Go to error
                  handleError(error);
                }
              } catch (error) {
                // Go to error
                handleError(error);
              }
            } else {
              // Go to error
              handleError(null);
            }

        } catch (err) {
          // handle errors
          handleError(null);
        }
    }

    if(!product) return null

    return (
      <ThemeProvider theme={theme}>
        <NavigationBar />
        <Container component="main" maxWidth="xl" style={{ marginTop: '50px', justifyContent: 'center' }}>
          <CssBaseline />
          <Grid container spacing={1}  style={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={12} md={6}>
              <h5>{product.name}</h5>
              <p>{product.shortDescription}</p>
              <Divider style={{marginBottom: 20}} />
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <p style={{marginRight: 20}}>Total</p>
                <p><strong>{product.price} RON</strong></p>
              </div>
              <Divider style={{marginBottom: 20}}/>
              <form onSubmit={handleSubmit}>
                  <MDBInput
                    label='Nume beneficiar card'
                    id='cardName'
                    type='text'
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    style={{ marginBottom: 20 }}
                  />
                  <div style={{border: '1px solid #bdbdbd', padding: 10, borderRadius: '0.25rem'}}>
                    <CardElement
                      options={{ hidePostalCode: true, style: {base: {marginBottom: 20, fontSize: '1rem'}} }}
                      onChange={(e) => setCardDetailsComplete(e.complete)}
                    />
                  </div>
                  <Button
                    disabled={!(cardDetailsComplete && cardName)}
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit} style={{marginTop: 20}}>
                      Plătește
                  </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
        <PageSpinner isLoading={paymentLoading} />
      </ThemeProvider>
    );
}
