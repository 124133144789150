import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PosterImage from '../../assets/placeholder.png';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from "../../redux/products/productsActions";
import NavigationBar from '../../components/NavigationBar';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';


const theme = createTheme();

export default function ProductPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const id = parseInt(params.id);
	const user = useSelector((state) => state.user);
	const { userInfo, success: userSuccess } = user;
	const { products, loading, error, success } = useSelector((state) => state.products);


	const [isOwnProduct, setIsOwnProduct] = useState(false);
	const [product, setProduct] = useState(null);

	const getShopData = useCallback(() => dispatch(getProducts()), [dispatch]);

	const handlePayment = () => navigate('/payment', { state: { product }});

	useEffect(() => {
		const ownProducts = user?.userInfo?.products || [];

		if (ownProducts.length) {
			const userProduct = ownProducts.find(product => product.id === id);
			if (userProduct) {
				setIsOwnProduct(true);
				setProduct(userProduct);
			} else {
				setIsOwnProduct(false);
				const product = products.find(prd => prd.id === id);
				if (product.attributes.price === 0) {
					setIsOwnProduct(true);
				}
				setProduct({...product.attributes, id: product.id});
			}
		}
	}, [user, id, products]);

	useEffect(() => {
		if (!isOwnProduct) {
			getShopData();
		}
	}, [getShopData, isOwnProduct]);

	if (!product) {
		return null
	}

	return (
		<ThemeProvider theme={theme}>
			<NavigationBar />
			<Container component="main" maxWidth="xl" style={{ marginTop: '50px' }}>
				<CssBaseline />
				<Grid container spacing={5}>
					<Grid item xs={12} sm={12} md={7}>
						<video
							poster={PosterImage}
							controls
							style={{ maxHeight: '500px', width: '100%', boxShadow: '1px 2px 5px 0px rgba(0,0,0,0.35)' }}>
							<source src={isOwnProduct ? product?.url : product?.introUrl} />
							Your browser does not support the video tag.
						</video>
					</Grid>
					<Grid item xs={12} sm={12} md={5}>
						<h4 style={{ textTransform: 'uppercase' }}>{product?.name}</h4>
						<Divider />
						{
							!isOwnProduct ? (<>
							<Button
								onClick={handlePayment}
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								<ShoppingBasketIcon  style={{marginRight: 10, fontSize: 20}}/> Cumpără
							</Button>
							<Divider />
						</>) : null
						}

						<p style={{ marginTop: '20px' }}>
							{product?.fullDescription}
						</p>
					</Grid>
				</Grid>
			</Container>
			<div style={{ marginTop: 50 }}></div>
		</ThemeProvider>
	)
};
