import React from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';

export default function PageSpinner({ isLoading }) {
  return isLoading ? (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: 100,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(128, 128, 128, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MDBSpinner role='status' color=''>
          <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
      </div>
    </>
  ) : null;
}
