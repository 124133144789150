import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PosterImage from '../../assets/placeholder.png';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from "../../redux/products/productsActions";
import NavigationBar from '../../components/NavigationBar';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';


const theme = createTheme();

export default function PublicProductPage({match}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const id = parseInt(params.id);
	const { products, loading, error } = useSelector((state) => state.products);

	const getShopData = useCallback(() => dispatch(getProducts()), [dispatch]);

	const [product, setProduct] = useState(null);

	const handlePayment = () => navigate('/public-payment', { state: { product }});

	useEffect(() => {
		getShopData();
	}, []);

	useEffect(() => {
		if(products.length) {
			const product = products.find(prd => prd.id === id);
			setProduct({...product.attributes, id: product.id});
		}
	}, [products]);

	if (!product) {
		return null
	}

	return (
		<ThemeProvider theme={theme}>
			<NavigationBar isGuest />
			<Container component="main" maxWidth="xl" style={{ marginTop: '50px' }}>
				<CssBaseline />
				<Grid container spacing={5}>
					<Grid item xs={12} sm={12} md={7}>
						<video
							poster={PosterImage}
							controls
							autoPlay={true}
							style={{ maxHeight: '500px', width: '100%', boxShadow: '1px 2px 5px 0px rgba(0,0,0,0.35)' }}>
							<source src={product.price === 0 ? product?.url : product?.introUrl} />
							Your browser does not support the video tag.
						</video>
					</Grid>
					<Grid item xs={12} sm={12} md={5}>
						<h4 style={{ textTransform: 'uppercase' }}>{product?.name}</h4>
						<Divider />
						{product.price !== 0 && <>
							<Button
								onClick={handlePayment}
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								<ShoppingBasketIcon  style={{marginRight: 10, fontSize: 20}}/> Cumpără
							</Button>
							<Divider />
						</>}

						<p style={{ marginTop: '20px' }}>
							{product?.fullDescription}
						</p>
					</Grid>
				</Grid>
			</Container>
			<div style={{ marginTop: 50 }}></div>
		</ThemeProvider>
	)
};
