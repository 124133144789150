import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import { MDBIcon } from 'mdb-react-ui-kit';

const theme = createTheme();

export default function PaymentSuccessPage() {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('userToken');
    const goToYourProducts = () => navigate("/produsele-mele");

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl" style={{ flex: 1, display: 'flex', height: '100vh' }}>
          <CssBaseline />
          <Grid container spacing={1} style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={6}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MDBIcon fas icon="check-circle" style={{fontSize: 70, marginBottom: 20, color: 'green'}}/>
                <p style={{textAlign: 'center', marginBottom: 50}}>Felicitări, plata a fost finalizată!</p>
                {isAuthenticated 
                ? <Button variant="contained" onClick={goToYourProducts}>Înapoi la produsele tale</Button> 
                : <Button variant="contained" onClick={() => navigate("/login")}>Autentifică-te</Button>}
              </div>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
}
